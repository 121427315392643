import React from 'react';
import '../../App.css';
import MediaContent from '../MediaContent';

export default function Media() {
  return (
    <div className='content-wrapper'>
      <div className='content'>
        <h1 className='media'>Media</h1>
        <MediaContent />
      </div>
    </div>
  );
}