import React from 'react';
import './MediaContent.css';
import { Link } from 'react-router-dom';
import BatmanPoster from '../assets/webp/Batman_Harvest_Moon_Poster.webp'
import FlowersPoster from '../assets/webp/FlowersPoster.webp'
import TotemPoster from '../assets/webp/TotemPoster.webp'

function MediaContent() {
  return (
    <div className='media-content-container'>
        <div className='media-content-items'>
            <Link to='/batman' className='media-img'>
                <img src={BatmanPoster} alt=''/>
            </Link>
            <Link to='/flowers' className='media-img'>
                <img src={FlowersPoster} alt=''/>
            </Link>
            <Link to='/totem' className='media-img'>
                <img src={TotemPoster} alt=''/>
            </Link>
        </div>
    </div>
  );
}

export default MediaContent;