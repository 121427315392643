import React, { useState } from 'react';
import './SideScrollingPictures.css'; // Create this CSS file for styling
import Img1 from '../assets/webp/theJointShop_04.webp'
import Img2 from '../assets/webp/theJointShop_05.webp'
import Img3 from '../assets/webp/theJointShop_06.webp'
import Img4 from '../assets/webp/theJointShop_08.webp'
import Img5 from '../assets/webp/theJointShop_10.webp'
import Img6 from '../assets/webp/Chef.webp'
import Img7 from '../assets/webp/Sauce.webp'
import Img8 from '../assets/webp/Smoothie.webp'
import Img9 from '../assets/webp/Espresso.webp'

const images = [
 
    Img6,
    Img7,
    Img8,
    Img9,
    Img1,
    Img2,
    Img3,
    Img4,
    Img5
  // Add more image filenames here
];

const SideScrollingBusiness = () => {
    const [selectedImage, setSelectedImage] = useState(null);
  
    const handleImageClick = (index) => {
      if (selectedImage === index) {
        // If the same image is clicked again, close it
        setSelectedImage(null);
      } else {
        setSelectedImage(index);
      }
    };
  
    return (
      <div className="scrolling-pictures-container">
        <div className="scrolling-pictures">
          {images.map((image, index) => (
            <div
              key={index}
              className={`picture ${selectedImage === index ? 'expanded' : ''}`}
              onClick={() => handleImageClick(index)}
            >
              <img src={image} alt={`Image ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    );
  };

export default SideScrollingBusiness;
