import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <small className='website-rights'>Pink Moose Productions © 2023</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link tiktok'
              to='https://www.tiktok.com/@pinkmooseproductions'
              target='_blank'
              aria-label='TikTok'
            >
              <i className='fab fa-tiktok' />
            </Link>
            <Link
              className='social-icon-link facebook'
              to='https://www.facebook.com/pinkmooseproductions'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
            <Link
              className='social-icon-link instagram'
              to='https://instagram.com/pinkmooseproductions?igshid=MzRlODBiNWFlZA=='
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </Link>
            <Link
              className='social-icon-link youtube'
              to='https://www.youtube.com/@Pinkmooseproductions'
              target='_blank'
              aria-label='Youtube'
            >
              <i className='fab fa-youtube' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;