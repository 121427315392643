import React from 'react';
import './Project.css'
import VideoPlayer from './VideoPlayer';

function Project(props) {
  return (
    <div className='project-container'>
        <div className='project-video'>
            <VideoPlayer src={props.src} thumbnail={props.thumbnail} />
        </div>
        <div className='project-text'>
            {props.text} 
        </div>
    </div>
  );
}

export default Project;