import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Start from './components/pages/Start'
import Home from './components/pages/Home';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Media from './components/pages/Media';
import WorkWithUs from './components/pages/WorkWithUs';
import About from './components/pages/About';
import Batman from './components/pages/Batman';
import Flowers from './components/pages/Flowers';
import Totem from './components/pages/Totem';
import Footer from './components/Footer';
import FilmFestival from './components/pages/FilmFestival';
import FilmFestivalRules from './components/pages/FilmFestivalRules';
import Corporate from './components/pages/Corporate';

 
function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Corporate />} />
          <Route path='/home' exact element={<Corporate />} />
          <Route path='/media' element={<Media />} />
          <Route path='/work-with-us' element={<WorkWithUs />} />
          <Route path='/about' element={<About />} />
          <Route path='/batman' element={<Batman />} />
          <Route path='/flowers' element={<Flowers />} />
          <Route path='/totem' element={<Totem />} />
          <Route path='/festival' element={<FilmFestival />} />
          <Route path='/festival-rules' element={<FilmFestivalRules />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;