import React from 'react';
import './Festival.css';
import TrophyImg from '../assets/webp/TrophyMoose.webp'
import PinkMooseFilmFest_LineUp from '../assets/webp/PinkMooseFilmFest_LineUp.webp'

function Festival () {
    return (
        <div className='festival'>
            <div className='festival-container'>
                <div className='festival-heading'>
                    <p>Join us for the first annual PinkMOOSE Film Festival on April 13, 2024.</p>
                </div>
                <div className='festival-description'>
                    <h3>Submissions are closed!</h3>
                    <p>Thank you to everyone who submitted a film!</p>
                    <p>We are thrilled to be able to share these short films so don't miss out!</p> 
                    <br/>
                    <p>Check back later after the Film Festival to view our after show stream and see who took home the honor and awards of being crowned the winners of the First Annual PinkMOOSE Film Festival!</p>
                </div>
                <div className='festival-description'>
                    <div>
                        <img src={PinkMooseFilmFest_LineUp} alt='' />
                    </div>
                    {/* <h3>Awards</h3>
                    <p>- Best Overall Film</p>
                    <p>- Most Creative Use of a Moose</p>
                    <p>- Most Creative Use of the Color Pink</p>
                    <p>- Best Performance</p>
                    <p>- Best Screenwriting</p>
                    <p>- Fan Favorite</p>
                    <br/>
                    <p>* There is no requirement to include a Moose or the Color Pink.</p> */}
                </div>
                <div className='festival-pic'>
                    <img src={TrophyImg} alt='' />
                </div>
            </div>
        </div>
    );
}

export default Festival;