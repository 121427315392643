import React, { useState } from 'react';
import './SideScrollingPictures.css'; // Create this CSS file for styling
import Img1 from '../assets/webp/Eloise_1.webp'
import Img2 from '../assets/webp/Eloise_2.webp'
import Img3 from '../assets/webp/Eloise_3.webp'
import Img4 from '../assets/webp/Norm_1.webp' 

const images = [
    Img1,
    Img2,
    Img3,
    Img4
  // Add more image filenames here
];

const SideScrollingPortrait = () => {
    const [selectedImage, setSelectedImage] = useState(null);
  
    const handleImageClick = (index) => {
      if (selectedImage === index) {
        // If the same image is clicked again, close it
        setSelectedImage(null);
      } else {
        setSelectedImage(index);
      }
    };
  
    return (
      <div className="scrolling-pictures-container">
        <div className="scrolling-pictures">
          {images.map((image, index) => (
            <div
              key={index}
              className={`picture ${selectedImage === index ? 'expanded' : ''}`}
              onClick={() => handleImageClick(index)}
            >
              <img src={image} alt={`Image ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    );
  };

export default SideScrollingPortrait;
