import React from 'react';
import '../../App.css';
import Collaborate from '../Collaborate';

export default function WorkWithUs() {
  return (
  <div className='content-wrapper'>
    <div className='content'>
      <h1 className='work-with-us'>Got a Story to Tell?</h1>
      <Collaborate/>
    </div>
  </div>
  );
}