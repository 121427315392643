import React from 'react';
import '../../App.css'; 
import HeroVideo from '../HeroVideo';
import HeroNews from '../HeroNews';

function Home() {
  return (
    <div className='content-wrapper'>
      <div className='content'>
        <HeroVideo />
        <HeroNews />
      </div>
    </div>
  );
}

export default Home;