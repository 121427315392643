import React from 'react';
import '../../App.css'; 
import Landing from '../Landing';

function Corporate() {
  return (
    <div className='content-wrapper'>
      <div className='content'> 
        <Landing />
      </div>
    </div>
  );
}

export default Corporate;