import React from 'react';
import './FestivalRules.css';
import TrophyImg from '../assets/webp/TrophyMoose.webp'

function FestivalRules () {
    return (
        <div className='festival-rules'>
            <div className='festival-rules-container'>
                <div className='festival-rules-heading'>
                    <p>Join us for the first annual PinkMOOSE Film Festival on April 13, 2024.</p>
                </div>
                <div className='festival-rules-description'>
                    <h3>**GENERAL ELIGIBILITY:**</h3>
                    <p>1. Submissions are free to enter but must include the relevant completed submission online entry form. Films must be in English or have English subtitles upon submission.</p> 
                    <p>2. Submissions must be made as online streaming videos through a participating platform or a password-protected streaming URL. Physical format submissions are not accepted.</p>
                    <p>2a. In case of a password or link change post-submission, promptly notify the Festival at Festival@pinkmooseproductions.com to update your record.</p>
                    <p>3. PMF screens films as digital files; Blu-Ray, HD-CAM, or DCP formats are not accepted. If unable to provide a digital file, refrain from submission.</p>
                    <p>4. Short Films should be completed after January 1, 2023, and available for screening during the Pink Moose Film Festival, April 13, 2024.</p>
                    <p>5. Films with US distribution or major studio backing are ineligible for competition. Films previously shown on US TV or theaters before the festival dates are also ineligible.</p>
                    <p>6. Short Films played in Chicago theaters before October 24, 2024, are ineligible. Exceptions include short films screened as part of an accredited university’s film program.</p>
                    <p>7. Short films publicly available online before the Festival may be considered for exhibition but are ineligible for Jury Awards.</p>
                    <p>8. Private invite-only screenings do not affect eligibility.</p>
                    <p>9. PMF reserves the right to reject or disqualify ineligible entries.</p>
                    <br/>
                    <h3>**ORIGINALITY AND PERMISSIONS; INDEMNITY:**</h3>
                    <p>1. Short Films must be original. Adaptations require secured rights, though agreements need not be submitted. Entrants affirm securing all necessary rights.</p> 
                    <p>2. Applicants are responsible for obtaining rights and permissions for third-party materials in their films. PMF disclaims liability for any violations.</p> 
                    <h3>**ADDITIONAL INFORMATION:**</h3>
                    <p>1. Films without a full-color or sound edit are eligible. PMF prefers picture-locked submissions. Technical changes won't affect decisions.</p> 
                    <p>2. Filmmakers unsure of the category may choose a genre category, and PMF may assign a category based on programming needs.</p> 
                    <p>3. PMF won't disclose information about screeners, judges, or submission review details beyond the rules.</p> 
                    <p>4. Winners will be announced on April 13, 2024. Judges' decision is final.</p> 
                    <p>5. PMF may require Closed-Caption and/or Subtitle files for accessibility. All accepted filmmakers will be notified promptly.</p> 
                    <br/> 
                </div>
                <div className='festival-rules-pic'>
                    <img src={TrophyImg} alt='' />
                </div>
            </div>
        </div>
    );
}

export default FestivalRules;