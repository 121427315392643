import React from 'react';
import '../../App.css';
import vid1 from '../../assets/videos/Batman_Harvest_Moon.mov';
import img1 from '../../assets/webp/Batman_Harvest_Moon_Thumbnail.webp';
import vid2 from '../../assets/videos/Harvest_Moon_Trailer_1.mp4';
import img2 from '../../assets/webp/Harvest_Moon_Trailer_1_Thumbnail.webp';
import vid3 from '../../assets/videos/Harvest_Moon_Trailer_2.mp4';
import img3 from '../../assets/webp/Harvest_Moon_Trailer_2_Thumbnail.webp';
import Project from '../Project';

export default function Batman() {
  return (
    <div className='content-wrapper'>
      <div className='content'>
        <h1 className='media-item'>Batman: Harvest Moon</h1>
        <Project src={vid1} thumbnail={img1} text="The Caped Crusader continues to his war against crime in the desolate Gotham City. When a young woman is found murdered, Batman's attention becomes pointed towards a new threat rising in the underworld, a harbinger of chaos, only known as 'The Scarecrow'. A DC Fanfilm set in the Matt Reeves Batman Universe."/>
        <div className='trailers'>
        <Project src={vid2} thumbnail={img2} text=""/>
        </div>
        <div className='trailers'>
        <Project src={vid3} thumbnail={img3} text=""/>
        </div>
      </div>
    </div>
  );
}