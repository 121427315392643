import React from 'react';
import ReactPlayer from 'react-player/file'
import './VideoPlayer.css'

function VideoPlayer(prop) {
  return (
    <div className='player-wrapper'>
      <ReactPlayer className='react-player' url={prop.src} light={<img src={prop.thumbnail} alt='Thumbnail' />} width='100%' height='100%' controls={true} playing={true} config={{ file: { attributes: { controlsList: 'nodownload'} } }} onContextMenu={e => e.preventDefault()} />
    </div>
  );
}

export default VideoPlayer;