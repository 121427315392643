import React from 'react';
import './Collaborate.css';
import MooseImg from '../assets/webp/moose_headlights_backlit_pink.webp'

function Collaborate () {
    return (
        <div className='collaborate'>
            <h1>If you have a story you are passionate about and would like to work with us on it, send us a pitch. Or if you are interested in working on an upcoming project of ours, give us a shout.</h1>
            <h1>contact@pinkmooseproductions.com</h1>
            <img src={MooseImg} alt=''/>
        </div>
    );
}

export default Collaborate;