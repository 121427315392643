import React from 'react';
import '../../App.css';
import Festival from '../Festival';

export default function FilmFestival() {
  return (
    <div className='content-wrapper'>
      <div className='content'>
        <h1 className='film-festival'>PinkMOOSE Film Festival</h1>
        <Festival />
      </div>
    </div>
  );
}