import React from 'react';
import VideoPlayer from './VideoPlayer';
import './HeroVideo.css';
import vid1 from '../assets/videos/Batman_Trailer.mov';
import img1 from '../assets/webp/Batman_Harvest_Moon_Thumbnail.webp';

function Videos() {
  return (
    <div className='hero-vid-container'>
        <VideoPlayer src={vid1} thumbnail={img1} />
    </div>
  );
}

export default Videos;