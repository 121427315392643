import React from 'react';
import './Team.css';
import TeamImg from '../assets/webp/231202_TeamPhoto_Large.webp'

function Team() {
  return (
  <div className='team'>
    <div className='team-container'>
      <div className='team-description'>
        Founded in 2023, Pink Moose Productions set out to make films with the idea that the best stories come from a collaborative and fun space. That idea has led us to work with many great artists who we call friends. We are always looking for creatives to work with on our own projects as well as to help support the vision of their own stories. If you have a story you are passionate about or want to get involved in a future project of ours, email us at contact@pinkmooseproductions.com, we would love to hear from you!
      </div>
      <div className='team-wrapper'>
        <img src={TeamImg} alt='' />
      </div>
    </div>
  </div>
  );
}

export default Team;