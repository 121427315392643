import React from 'react';
import '../../App.css';
import vid1 from '../../assets/videos/FlowersGrowOutOfMyGrave_HalfSize.mov';
import img1 from '../../assets/webp/Flowers_Grow_Out_Of_My_Grave_Thumbnail.webp';
import Project from '../Project';

export default function Flowers() {
  return (
    <div className='content-wrapper'>
      <div className='content'>
        <h1 className='media-item'>Flowers Grow Out of My Grave</h1>
        <Project src={vid1} thumbnail={img1} text="Theodore Monroe IX travels the country looking to spread the word of the good Lord and Savior Jesus Christ, but like many of God's disciples he will face his tests and trials in a world of evil. Milkwaukee Film Festival short" />
      </div>
    </div>
  );
}