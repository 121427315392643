import React from 'react';
import '../../App.css';
import FestivalRules from '../FestivalRules';

export default function FilmFestivalRules() {
  return (
    <div className='content-wrapper'>
      <div className='content'>
        <h1 className='film-festival-rules'>PMP Film Festival Rules</h1>
        <FestivalRules />
      </div>
    </div>
  );
}