import React from 'react';
import '../App.css';
import img1 from '../assets/webp/pink-moose-logo-black-square-web-background.webp'

function StartSection() {
  return (
    <div className='start-container'>
        <div className='fullscreen-image'>
            <img src={img1} alt='' />
        </div>
    </div>
  );
}

export default StartSection;