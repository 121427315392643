import React from 'react';
import '../../App.css';
import vid1 from '../../assets/videos/Totem_Trailer.mov';
import img1 from '../../assets/webp/Totem_Trailer_Thumbnail.webp';
import Project from '../Project';

export default function Totem() {
  return (
    <div className='content-wrapper'>
      <div className='content'>
        <h1 className='media-item'>Totem</h1>
        <Project src={vid1} thumbnail={img1} text="An ancient and long forgotten spirit, restless and powerful, has awoken."/>
      </div>
    </div>
  );
}