import React from 'react';
import './Landing.css';
import SideScrollingBusiness from './SideScrollingBusiness';
import SideScrollingProduct from './SideScrollingProduct';
import SideScrollingPortrait from './SideScrollingPortrait';
import Img1 from '../assets/webp/PhotographyBanner_2.webp';
import Img2 from '../assets/webp/BusinessPackage.webp'
import Img3 from '../assets/webp/ProfessionalPackage.webp'
import Img4 from '../assets/webp/StudioPackage.webp'


function Landing() {
  return (
    <div className='landing'>
        <div className='landing-container'> 
            <div className='landing-imgs'>
                <img src={Img1} alt=''/>
            </div> 
            <div className='landing-scroll'>
                <h1 class="line-title"><span>Business</span><hr /></h1>
                <SideScrollingBusiness/>
                <h1 class="line-title"><span>Studio</span><hr /></h1>
                <SideScrollingProduct/>
                <h1 class="line-title"><span>Lifestyle</span><hr /></h1>
                <SideScrollingPortrait/>
            </div> 
            <div className='landing-packages'>
                <img src={Img2} alt='' />
            </div>
            <div className='landing-packages-2'>
                <img src={Img3} alt='' />
            </div>
            <div className='landing-packages'>
                <img src={Img4} alt='' />
            </div>
        </div>
    </div>
  );
}

export default Landing;