import React from 'react';
import '../../App.css';
import Team from '../Team';
import img1 from '../../assets/webp/Group_Banner.webp';

function About() {
    return (
      <div className='content-wrapper'>
        <div className='content'>
          <h1 className='about'>Pink Moose Productions || Chicago IL</h1>
          <div className='padded'>
            <img src={img1} alt=''/>
          </div>
          <Team />
        </div>
      </div>
    );
  }
  
  export default About;